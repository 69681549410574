angular.module("app")
    .controller("dashboardController", ["$scope", "$rootScope", "$log", "$state", "$location", "userService", "authenticatedStatus", function ($scope, $rootScope, $log, $state, $location, userService, authenticatedStatus) {
        var vm = this;
        vm.user = {
            username: null,
            authenticated: false
        };

        checkAuthenticated();
        $scope.$on("unauthorized", function () {
            $state.go("signin");
        });

        $scope.$on("serverMaintenance", function(event, data) {
            var params = {message: data};
            $state.go("maintenance", params);
        });

        // $scope.$watch(function(scope){
        //     return authenticatedStatus.directUser;
        // }, function(user){
        //     vm.user = user;
        // });

        $scope.$watch(function (scope) {
            return authenticatedStatus.directUser;
        }, function (newValue, oldValue) {

            if(_.isEqual(newValue, oldValue)) return;

            userService.getOrganisation()
                .then(function (result) {
                    $rootScope.organisation = result;
                    vm.user = newValue;
                });
        });
        
		vm.signout = function(){
			userService.signOut()
				.then(function(){
                    vm.user = undefined;
                    delete $rootScope.department;
                    delete $rootScope.organisation;
                    localStorage.removeItem('department');
					$state.go("signin");
				});
		};
		
		function checkAuthenticated(){
			return userService.isAuthenticated()
				.then(function(status){
					if(status.authenticated){
                        vm.user = status.user;
                        vm.user.authenticated = true;
                        $log.debug("vm.user is %o", vm.user);
					} else {
                        authenticatedStatus.user = undefined;
                        authenticatedStatus.directUser = undefined;
                        vm.user = undefined;
                        $state.go("signin");
                    }
                });
        }
    }]);
